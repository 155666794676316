import { SelectOption } from "../interfaces/select-option.interface";

export const YesOrNoOptions: SelectOption[] = [
  {
    text: "Yes",
    value: true
  },
  {
    text: "No",
    value: false
  }
];